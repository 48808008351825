import React, { useState } from "react";
import { fromJS, Map, OrderedMap, Set } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withHandlers,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormAutoComplete from "../form/FormAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import {
  isEmpty,
  isEqualData,
  isEqualWithoutFunctions,
  toJS,
} from "../../helpers/DataUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { Delete, KeyboardArrowLeft, Save } from "@material-ui/icons";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { TransitionUp } from "../dialog/TransitionUp";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { RENDER } from "./MUITable";
import { PREPARED_FOR_TRANSIT } from "../../constants/OrderStatusCodes";
import { renderIf } from "../../helpers/HOCUtils";
import FormDialog from "../form/FormDialog";
import ScannerTextField from "../deprecated/ScannerTextField";
import { Observable } from "rxjs";
import { getHybridOrder } from "../../api/admin/AdminOrderApi";
import {
  addHybridOrder,
  deleteHybridOrder,
  getHybridOrders,
  updateHybridOrder,
} from "../../reducers/HybridOrdersReducer";
import DateTimeCell from "../data-list/DateTimeCell";
import { mergeSideEffectStreams, pipeStreams } from "../../helpers/StreamUtils";
import { red } from "@material-ui/core/colors";
import HybridOrdersSimpleTable from "./HybridOrdersSimpleTable";

export const POST_PACKET = "post_packet";
export const PARCEL = "parcel";

const formatOrderStatus = (status, getLocalisationMessage) =>
  formatOrderStatusCodeForLocalisation(status, getLocalisationMessage);

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: theme.palette.appBarTextColor,
    backgroundColor: theme.palette.primary1Color,
  },
  chip: { margin: "4px" },
  moreInfo: { marginTop: "5px", marginLeft: "10px" },
  form: {
    paddingTop: 15,
    "&> div": {
      marginBottom: 15,
    },
  },
  dialogHeaderContainer: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
  },
  dialogHeaderTitle: {
    flexGrow: 1,
  },
  dialogHeaderToggleContainer: {
    padding: "10px 5px 10px 20px",
    borderRadius: 1000,
    backgroundColor: "#DBE1E6",
  },
  error: {
    color: red[400],
    fontWeight: "bold",
  },
}));

const getPendingOrders = orders =>
  orders.filter(order => !order.has("id") && !order.has("error"));

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      orders: getHybridOrders(state),
    }),
    { showErrorMessage, addHybridOrder, deleteHybridOrder, updateHybridOrder },
  ),
  withState("state", "setState", {
    prefetchPending: false,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          handler: onScanOrder,
          stream: onScanOrderStream,
        } = createEventHandler();

        const initialValuesStream = propsStream
          .map(fp.flow(fp.set("orderStatus", PREPARED_FOR_TRANSIT)))
          .distinctUntilChanged(isEqualData);

        const handleScanOrderStream = onScanOrderStream
          .filter(orderNumber => !isEmpty(fp.trim(orderNumber)))
          .distinctUntilChanged()
          .withLatestFrom(propsStream)
          .map(([orderNumber, props]) => {
            props.addHybridOrder(orderNumber);

            return orderNumber;
          })
          .startWith(null);

        return propsStream
          .combineLatest(
            initialValuesStream,
            handleScanOrderStream,
            (props, initialValues) => ({
              ...props,
              initialValues,
              onScanOrder,
              isLoading: false,
            }),
          )
          .distinctUntilChanged(isEqualWithoutFunctions);
      },

      propsStream => {
        const sideEffectsStream = mergeSideEffectStreams(
          Observable.interval(1000)
            .withLatestFrom(propsStream)
            .filter(([, props]) => !fp.get("prefetchPending", props.state))
            .map(([, props]) => fp.pick(["orders"])(props))
            // .distinctUntilChanged(isEqualDataIn)
            .map(props => {
              const { orders } = props;
              const unloaded = getPendingOrders(orders);
              if (unloaded.size > 0) {
                const first = unloaded.keySeq().first();
                return orders.get(first);
              }
              return null;
            })
            .filter(order => Map.isMap(order))
            .withLatestFrom(propsStream)
            .switchMap(([order, props]) => {
              props.setState(fp.set("prefetchPending", true));
              const barcode = order.get("barcode");

              return getHybridOrder({ barcode })
                .catch(error => {
                  Observable.of({ error });
                })
                .map(
                  fp.flow(
                    response => fromJS(response),
                    response =>
                      fromJS({
                        error: response.get("error", null),
                        isLoading: response.get("pending", false),
                        data: response.getIn(["payload", "data"], Map()),
                      }),
                  ),
                )
                .do(response => {
                  if (!response.get("isLoading")) {
                    if (
                      response.hasIn(["data", "id"]) &&
                      !response.get("error")
                    ) {
                      props.updateHybridOrder(
                        order.get("barcode"),
                        response.get("data"),
                      );
                    } else if (response.get("error")) {
                      props.updateHybridOrder(
                        order.get("barcode"),
                        Map({
                          barcode,
                          error: true,
                          message: response.get("error"),
                        }),
                      );
                    } else {
                      // props.showErrorMessage("order_not_found");
                      props.updateHybridOrder(
                        order.get("barcode"),
                        Map({
                          barcode,
                          error: true,
                          message: props.getLocalisationMessage(
                            "order_not_found",
                          ),
                        }),
                      );
                    }
                  }
                  props.setState(fp.set("prefetchPending", false));
                });
            }),
        ).startWith(null);

        return propsStream.merge(sideEffectsStream);
      },

      // (propsStream) => {
      //     const sideEffectsStream =
      //         Observable.merge(propsStream
      //
      //             .map(fp.pick(["orders"]))
      //             .distinctUntilChanged(isEqualData)
      //             .map(props => {
      //                 const {orders} = props;
      //                 const unloaded = orders.filter(order => !order.has("id") && !order.has("error"));
      //
      //                 console.log("test")
      //
      //                 if (unloaded.size > 0) {
      //                     const first = unloaded.keySeq().first();
      //                     console.log("unload", orders.toJS(), unloaded.toJS(), first, orders.get(first));
      //                     return orders.get(first)
      //                 }
      //
      //                 return null;
      //             })
      //             .filter(order => {
      //                 console.log("order", order, Map.isMap(order))
      //                 return Map.isMap(order)
      //             })
      //             // .distinctUntilChanged(isEqualDataIn)
      //             .switchMap((order) => {
      //                 console.log("barcode",order.get("barcode"))
      //                 const barcode = order.get("barcode");
      //
      //                 return getHybridOrder({barcode})
      //                     .catch((error) => {
      //                         Observable.of({error})
      //                     })
      //                     .map(
      //                         fp.flow(
      //                             (response) => fromJS(response),
      //                             (response) =>
      //                                 fromJS({
      //                                     error: response.get("error", null),
      //                                     isLoading: response.get("pending", false),
      //                                     data: response.getIn(["payload", "data"], Map()),
      //                                 }),
      //                         ),
      //                     )
      //                     .withLatestFrom(propsStream)
      //                     .do(([response, props]) => {
      //                         console.log("do", response)
      //                         if (!response.get("isLoading")) {
      //                             if (response.hasIn(["data", "id"]) && !response.get("error")) {
      //                                 props.updateHybridOrder(order.get("barcode"), response.get("data"));
      //                             } else if (response.get("error")) {
      //                                 props.updateHybridOrder(order.get("barcode"), Map({
      //                                     barcode,
      //                                     error: true,
      //                                     message: response.get("error")
      //                                 }));
      //                             } else {
      //                                 props.showErrorMessage("order_not_found")
      //                                 props.updateHybridOrder(order.get("barcode"), Map({
      //                                     barcode,
      //                                     error: true,
      //                                     message: props.getLocalisationMessage("order_not_found")
      //                                 }));
      //                             }
      //                         }
      //                     })
      //
      //             })).startWith(null).repeatWhen(stream => stream.delay(1000))
      //
      //     return propsStream.combineLatest(sideEffectsStream, fp.identity);
      //
      // }
    ),
  ),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }
      const { orders } = props;

      const successOrders = orders.filter(order => order.has("id"));

      return props.onSubmit({
        ...values,
        orderBarcodes: successOrders.keySeq().toArray(),
      });
    },
  }),
  reduxForm({
    form: "HybridCreatePostPackageForm",
    validate: (values, props) => ({
      orderBarcodes:
        fp.isEmpty(values.orderBarcodes) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("add_orders")) ||
          "Add Orders"),
      orderStatus:
        !toJS(props.statusCodes).some(v => v === values.orderStatus) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_status")) ||
          "Select Status"),
      category:
        fp.isEmpty(values.category) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      warehouse:
        !isValidObjectId(values.warehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
      to_warehouse:
        !isValidObjectId(values.to_warehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
    }),
  }),
);

HybridCreatePostPackageForm.propTypes = {
  isLoadingSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  open: PropTypes.bool.isRequired,

  statusCodes: PropTypes.instanceOf(Set).isRequired,
  orders: PropTypes.instanceOf(OrderedMap),

  onScanOrder: PropTypes.func,
  handleSubmit: PropTypes.func,
  deleteHybridOrder: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
};

function HybridCreatePostPackageForm(props) {
  const {
    getLocalisationMessage,
    open,
    statusCodes,
    onRequestClose,
    handleSubmit,
    onScanOrder,
    orders,
    isLoadingSubmit,
    isLoading,
  } = props;

  const classes = useStyles();

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenWrongCustomerDialog, setIsOpenWrongCustomerDialog] = useState(
    false,
  );

  const isMaxOrdersCountReached = orders.size === 100;
  const pendingOrders = getPendingOrders(orders);
  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={open}
      maxWidth="lg"
      fullWidth={true}
      // onClose={onRequestClose}
    >
      <FormDialog
        open={isOpenDialog}
        onRequestClose={() => setIsOpenDialog(false)}
        onSubmit={() => {
          setIsOpenDialog(false);
          onRequestClose();
        }}
      >
        {getLocalisationMessage("are_you_sure_you_want_to_exit")}
      </FormDialog>

      <Dialog
        TransitionComponent={TransitionUp}
        open={isOpenWrongCustomerDialog}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setIsOpenWrongCustomerDialog(false)}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.dialogHeaderContainer}>
            <div className={classes.dialogHeaderTitle}>
              {getLocalisationMessage("error_message")}
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <FlexBox
            flex={true}
            justify={JUSTIFY_CENTER}
            style={{ margin: "2rem 0" }}
          >
            <h5>
              {getLocalisationMessage(
                "bu_order_tanlangan_customerga_tegishli_emash",
              )}
            </h5>
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <FlexBox flex={true} justify={JUSTIFY_CENTER}>
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              startIcon={<KeyboardArrowLeft />}
              primary={true}
              onClick={() => setIsOpenWrongCustomerDialog(false)}
            >
              {getLocalisationMessage("back")}
            </CustomButton>
          </FlexBox>
        </DialogActions>
      </Dialog>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.dialogHeaderContainer}>
          <FlexBox
            style={{
              flexGrow: 1,
              justifyContent: "space-between",
            }}
          >
            <span>{getLocalisationMessage("create_post_paket")}</span>
            <span>
              {`${getLocalisationMessage("number_of_items")} : ${orders.size}`}
            </span>
          </FlexBox>
        </div>
      </DialogTitle>
      <DialogContent>
        {isMaxOrdersCountReached && (
          <h4
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              color: "red",
            }}
          >
            {getLocalisationMessage("maximum_number_of_orders_reached")}
          </h4>
        )}
        <form className={classes.form}>
          <FlexBox>
            <FlexBox
              direction="column"
              style={{ width: "80%", paddingRight: "1rem" }}
            >
              <ScannerTextField
                fullWidth={true}
                hintText={getLocalisationMessage("add_orders", "Add Orders")}
                focus={true}
                className={classes.input}
                id="OrderHybridFormScannerTextField"
                onChange={onScanOrder}
                clearAfterEnter={true}
              />

              <LinearProgress
                style={{
                  opacity: isLoading ? 1 : 0,
                  transition: "all 0.1s ease",
                  margin: ".5rem 0",
                  // width: "100%"
                }}
                mode="indeterminate"
                color="secondary"
              />

              {orders.size > 0 && (
                <HybridOrdersSimpleTable
                  size="small"
                  list={orders.reverse().toArray()}
                  columns={[
                    {
                      type: RENDER,
                      name: "barcode",
                      label: getLocalisationMessage("barcode"),
                      render: row => row.get("barcode", ""),
                    },
                    {
                      type: RENDER,
                      name: "recipient",
                      label: getLocalisationMessage("recipient"),
                      render: row => row.getIn(["recipient", "name"], ""),
                    },
                    {
                      type: RENDER,
                      name: "jurisdiction",
                      label: getLocalisationMessage("jurisdiction"),
                      render: row =>
                        row.getIn(["recipient", "jurisdiction", "name"], ""),
                    },
                    {
                      type: RENDER,
                      name: "recipient_address",
                      label: getLocalisationMessage("recipient_address"),
                      render: row => row.getIn(["recipient", "address"], ""),
                    },
                    {
                      type: RENDER,
                      name: "created_date",
                      keyName: "created_date",
                      label: getLocalisationMessage("created_date"),
                      render: row => (
                        <DateTimeCell date={row.get("created_date", "")} />
                      ),
                    },
                    {
                      type: RENDER,
                      name: "action",
                      align: "right",
                      render: row => (
                        <CustomButton
                          onClick={() => {
                            // eslint-disable-next-line react/prop-types
                            props.deleteHybridOrder(row.get("barcode"));
                          }}
                          endIcon={<Delete />}
                        >
                          {getLocalisationMessage("delete")}
                        </CustomButton>
                      ),
                    },
                  ]}
                />
              )}
            </FlexBox>

            <FlexBox direction="column" style={{ width: "20%" }}>
              <FormAutoComplete
                disabled={true}
                name="orderStatus"
                fullWidth={true}
                label={getLocalisationMessage("status", "Status")}
                options={statusCodes}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
                formatOption={x => formatOrderStatus(x, getLocalisationMessage)}
                margin="normal"
              />

              <FormWarehouseAutoComplete
                disableP7={false}
                name="to_warehouse"
                label={getLocalisationMessage("destination_warehouse")}
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
                margin="normal"
              />

              <FormTextField
                type="number"
                name="weight"
                fullWidth={true}
                label={getLocalisationMessage("weight_kg", "Weight (kg)")}
                margin="normal"
              />
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <FlexBox
          style={{ marginBottom: "1rem" }}
          gutter={16}
          flex={true}
          justify={JUSTIFY_CENTER}
        >
          <CustomButton
            style={{ marginRight: "1rem" }}
            variant={CONTAINED}
            color={SECONDARY}
            startIcon={<KeyboardArrowLeft />}
            primary={true}
            onClick={() => setIsOpenDialog(true)}
          >
            {getLocalisationMessage("close")}
          </CustomButton>
          <CustomButton
            disabled={isLoadingSubmit || pendingOrders.size > 0}
            style={{ marginLeft: "1rem" }}
            variant={CONTAINED}
            color={SECONDARY}
            endIcon={
              isLoadingSubmit ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <Save />
              )
            }
            primary={true}
            onClick={() => {
              handleSubmit();
            }}
          >
            {getLocalisationMessage("create")}
          </CustomButton>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(HybridCreatePostPackageForm);
