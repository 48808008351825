import { Observable } from "rxjs";
import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { createCustomer } from "../../api/admin/AdminCustomerApi";
import ResponseError from "../../helpers/ResponseError";
import AdminCustomerForm from "../../components/admin/AdminCustomerForm";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { CUSTOMER_ITEM_URL } from "../../constants/AdminPathConstants";
import { ACTIVE } from "../../constants/OverallStatus";
import { MERCHANTS } from "../../constants/MerchantTypes";

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream((propsStream) => {
    const marketplaceIdStream = getMarketplace()
      .takeLast(1)
      .catch(() => Observable.of({}))
      .map(
        fp.flow(
          fp.get("payload.data"),
          fp.toPlainObject,
          Immutable.fromJS,
          (x) => x.get("marketplace_id"),
        ),
      );

    return propsStream
      .combineLatest(marketplaceIdStream, (props, marketplaceId) => ({
        ...props,
        marketplaceId,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

const AdminCustomerCreate = ({
  getLocalisationMessage,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  setLocation,
}) => (
  <AdminAppLayout title={getLocalisationMessage("customer", "Customer")}>
    <AdminCustomerForm
      initialValues={{
        status: ACTIVE,
        merchantType: MERCHANTS,
      }}
      onSubmit={fp.flow(toSnakeCase, (values) =>
        createCustomer(values).catch(ResponseError.throw),
      )}
      onSubmitSuccess={(res) => {
        showSuccessMessage1(
          getLocalisationMessage("successfully_saved", "Successfully Saved"),
        );
        setLocation(
          `${CUSTOMER_ITEM_URL + fp.get("data.customer_id", res)}/details`,
        );
      }}
      onSubmitFail={showErrorMessage1}
    />
  </AdminAppLayout>
);
AdminCustomerCreate.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  setLocation: PropTypes.func,
};

export default enhancer(AdminCustomerCreate);
