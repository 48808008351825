import React, { useEffect, useState } from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, withContext } from "recompose";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { FieldArray, formValues, getFormValues, reduxForm } from "redux-form";
import { Card, CardContent, Fab, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import QRCode from "qrcode-react";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormWarehouseChips from "../form/FormWarehouseChips";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getValue, isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import {
  isValidCoordinates,
  isValidObjectId,
  validateString,
} from "../../helpers/ValidateUtils";
import {
  parseMultipolygon,
  parseMultipolygonArray,
} from "../../helpers/MapPolygonUtils";
import { getMarketplaceCountry } from "../../reducers/MarketplaceReducer";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import OverallStatus from "../../constants/OverallStatus";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import {
  DataPolygon as LeafletDataPolygon,
  FitBounds,
  PanTo as LeafletPanTo,
} from "react-leflet-map-components";
import LeafletBrandMarker from "../maps/osm/BrandMaker";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import FormCheckbox from "../form/FormCheckbox";
import PostcodeAutoCompleteWithJM from "../form/PostcodeAutoCompleteWithJM";
import _ from "lodash";
import { api } from "../../api/shared/BaseApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { WAREHOUSE_LIST_URL } from "../../actions/AdminWarehouseActions";
import FormTimeField from "../form/FormTimeField";
import { format } from "date-fns";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import MuiPhoneNumber from "material-ui-phone-number-uzpost";
import { MyLocation as MapsMyLocation, Save } from "@material-ui/icons";
import Geolocation from "../maps/Geolocation";
import { primary1 } from "../../../shared/theme/main-theme";

const BRANCH = "BRANCH";
const HUB = "HUB";
const REGION = "REGION";
const BRANCH_MAIN = "BRANCH_MAIN";
const MANAGEMENT_DEPARTMENT = "MANAGEMENT_DEPARTMENT";

const MONDAY = "MONDAY";
const TUESDAY = "TUESDAY";
const WEDNESDAY = "WEDNESDAY";
const THURSDAY = "THURSDAY";
const FRIDAY = "FRIDAY";
const SATURDAY = "SATURDAY";
const SUNDAY = "SUNDAY";

const weekDays = OrderedSet.of(
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
);

const WarehouseTypes = OrderedSet.of(
  BRANCH,
  HUB,
  REGION,
  BRANCH_MAIN,
  MANAGEMENT_DEPARTMENT,
);

const useStyles = makeStyles({
  numberOfRow: {
    minWidth: "2rem",
    height: "2rem",
    borderRadius: "50%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ".5rem",
    position: "relative",
    overflow: "visible",
    backgroundColor: "#4DB666",
  },
  before: {
    "&:before": {
      content: '""',
      top: "30px",
      left: "15px",
      width: "0",
      height: "50px",
      bottom: "10px",
      borderLeft: "1px dashed #5d6165",
      position: "absolute",
    },
  },
  map: {
    "& .leaflet-container": {
      zIndex: 0,
    },
  },
  point: {
    position: "absolute",
    padding: 40,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },
});

const WorkingDays = props => {
  const { getLocalisationMessage, fields, classes } = props;
  return (
    <FlexBox flex={true} direction="column">
      {fields.map((item, index) => (
        <FlexBox align={ALIGN_CENTER} key={index} flex={true} gutter={16}>
          <span
            className={`${classes.numberOfRow} ${fields.length > index + 1 &&
              classes.before}`}
          >
            {index + 1}
          </span>
          <FlexBox flex={true} style={{ marginTop: 8 }}>
            <FormSelectField
              name={`${item}.dayOfWeek`}
              size="small"
              fullWidth={true}
              options={weekDays}
              label={getLocalisationMessage("day", "day")}
              formatOption={option => getLocalisationMessage(option)}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTimeField
              margin="normal"
              name={`${item}.from`}
              fullWidth={true}
              label={getLocalisationMessage("from_date")}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTimeField
              margin="normal"
              name={`${item}.to`}
              fullWidth={true}
              label={getLocalisationMessage("to_date")}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormCheckbox
              name={`${item}.dayOff`}
              label={getLocalisationMessage("day_off", "Day Off")}
            />
          </FlexBox>
        </FlexBox>
      ))}
    </FlexBox>
  );
};
WorkingDays.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
};

const enhancer = compose(
  connect(
    state => ({
      baseCountry: getMarketplaceCountry(state),
      mapProvider: getMapProvider(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isRTL: getIsRTL(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const initialValuesStream = propsStream
          .distinctUntilKeyChanged("initialValues", isEqualData)
          .map(
            fp.flow(fp.update("initialValues", fp.toPlainObject), props => {
              const workingDays =
                props.initialValues && props.initialValues.workingDays
                  ? props.initialValues.workingDays.map(day => ({
                      ...day,
                      from: !fp.isNull(day.from)
                        ? new Date(`1970-01-01 ${day.from}`)
                        : day.from,
                      to: !fp.isNull(day.to)
                        ? new Date(`1970-01-01 ${day.to}`)
                        : day.to,
                    }))
                  : [
                      {
                        dayOfWeek: "MONDAY",
                        dayOff: false,
                      },
                      {
                        dayOfWeek: "TUESDAY",
                        dayOff: false,
                      },
                      {
                        dayOfWeek: "THURSDAY",
                        dayOff: false,
                      },
                      {
                        dayOfWeek: "WEDNESDAY",
                        dayOff: false,
                      },
                      {
                        dayOfWeek: "FRIDAY",
                        dayOff: false,
                      },
                      {
                        dayOfWeek: "SATURDAY",
                        dayOff: false,
                      },
                      {
                        dayOfWeek: "SUNDAY",
                        dayOff: false,
                      },
                    ];
              return {
                ...props.initialValues,
                workingDays,
                location: {
                  lat: props.initialValues.lat,
                  lng: props.initialValues.lon,
                  address: props.initialValues.address,
                },
              };
            }),
          );

        const onSubmit = ({ location, ...values }, dispatch, props) => {
          const workingDays = values.workingDays.map(day => ({
            ...day,
            from: day.from ? format(day.from, "HH:mm:ss") : null,
            to: day.to ? format(day.to, "HH:mm:ss") : null,
          }));

          return props.actualOnSubmit({
            ...values,
            workingDays,
            coverageType: "POSTAL_CODE",
          });
        };

        return propsStream.combineLatest(
          initialValuesStream,
          (props, initialValues) => ({
            ...props,
            initialValues,
            actualOnSubmit: props.onSubmit,
            onSubmit: props.onSubmit && onSubmit,
          }),
        );
      },
      propsStream => {
        const polygonStream = propsStream
          .distinctUntilKeyChanged("wkt")
          .map(fp.flow(fp.get("wkt"), parseMultipolygon));

        const polygonStreamYandex = propsStream
          .distinctUntilKeyChanged("wkt")
          .map(fp.flow(fp.get("wkt"), parseMultipolygonArray));

        const drawnPolygonStream = propsStream
          .distinctUntilKeyChanged("drawnPolygon")
          .map(fp.flow(fp.get("wkt"), parseMultipolygonArray));

        return propsStream.combineLatest(
          polygonStream,
          polygonStreamYandex,
          drawnPolygonStream,
          (props, polygon, polygonYandex, drawnPolygon) => ({
            ...props,
            polygon,
            polygonYandex,
            drawnPolygon,
          }),
        );
      },
    ),
  ),
  reduxForm({
    enableReinitialize: true,
    form: "AdminWarehouseForm",
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.getLocalisationMessage("name_is_required", "Name is required")
        : null,
      nameUz: validateString(values.nameUz)
        ? props.getLocalisationMessage("name_is_required", "Name is required")
        : null,
      nameRu: validateString(values.nameRu)
        ? props.getLocalisationMessage("name_is_required", "Name is required")
        : null,
      nameEn: validateString(values.nameEn)
        ? props.getLocalisationMessage("name_is_required", "Name is required")
        : null,
      jurisdiction: fp.isNil(values.jurisdiction)
        ? props.getLocalisationMessage(
            "jurisdiction_is_required",
            "Jurisdiction is required",
          )
        : null,
      postcode:
        !isValidObjectId(values.postcode) &&
        props.getLocalisationMessage(
          "postcode_is_required",
          "Postcode is required",
        ),
      type:
        !values.type &&
        props.getLocalisationMessage(
          "warehouse_type_is_required",
          "Warehouse Type is required",
        ),
    }),
  }),
  connect(state => ({
    values: getFormValues("AdminWarehouseForm")(state),
  })),
  formValues({
    wkt: "wkt",
    location: "location",
    drawnPolygon: "drawnPolygon",
  }),
);

AdminWarehouseForm.propTypes = {
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  polygon: PropTypes.array,
  location: PropTypes.object,
  values: PropTypes.object,
  warehouseId: PropTypes.number,
  getLocalisationMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
};

function AdminWarehouseForm({
  change,
  getLocalisationMessage,
  handleSubmit,
  polygon,
  submitting,
  warehouseId,
  values,
  showErrorMessage: showErrorMessage1,
}) {
  const classes = useStyles();

  const recipientCountryId = _.get(values, "jurisdiction.id");

  const countryCode = getValue(values, "jurisdiction.country_code")
    ? getValue(values, "jurisdiction.country_code")
    : getValue(values, "jurisdiction.countryCode");

  const [serviceTypesIsLoading, setServiceTypesIsLoading] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);

  useEffect(() => {
    setServiceTypesIsLoading(true);
    api
      .get(WAREHOUSE_LIST_URL, {
        params: {
          size: 200,
          page: 0,
          warehouseType: "LOCKER_POST",
        },
      })
      .then(res => setServiceTypes(_.get(res, "data.list")))
      .catch(error => showErrorMessage1(error))
      .finally(() => setServiceTypesIsLoading(false));
  }, [warehouseId]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Card>
        <PageLoading isLoading={submitting} />

        <CardContent>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} direction="column" style={{ gap: 10 }}>
              <FormTextField
                name="name"
                margin="normal"
                label={`${getLocalisationMessage("name", "Name")} *`}
                fullWidth={true}
              />
              <FormTextField
                name="nameUz"
                margin="normal"
                label={`${getLocalisationMessage("title_uz")} *`}
                fullWidth={true}
              />
              <FormTextField
                name="nameRu"
                margin="normal"
                label={`${getLocalisationMessage("title_ru")} *`}
                fullWidth={true}
              />
              <FormTextField
                name="nameEn"
                margin="normal"
                label={`${getLocalisationMessage("title_eng")} *`}
                fullWidth={true}
              />

              <FlexBox>
                <FormCheckbox
                  name="public"
                  label={getLocalisationMessage("public")}
                />
                <FormTextField
                  disabled={!fp.get("public", values)}
                  name="publicName"
                  margin="normal"
                  label={getLocalisationMessage("public_name")}
                  fullWidth={true}
                />
                {/* </FlexBox> */}
              </FlexBox>
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true}>
                  <FormJMAutoComplete
                    fullWidth={true}
                    margin="normal"
                    name="jurisdiction"
                    label={`${getLocalisationMessage(
                      "jurisdiction",
                      "Jurisdiction",
                    )} *`}
                    renderOption={option => (
                      <FlexBox direction="column">
                        <span>{option.name}</span>
                        <span
                          style={{
                            fontSize: ".8rem",
                            fontStyle: "italic",
                          }}
                        >
                          {option.value.hierarchy.map((item, index, arr) =>
                            index === arr.length - 1
                              ? item.name
                              : `${item.name} > `,
                          )}
                        </span>
                      </FlexBox>
                    )}
                  />
                </FlexBox>
              </FlexBox>
              <PostcodeAutoCompleteWithJM
                margin="normal"
                label={`${getLocalisationMessage("index", "Index")} *`}
                name="postcode"
                fullWidth={true}
                jurisdictionId={recipientCountryId}
                disabled={!recipientCountryId}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />

              <FormTextField
                name="address"
                label={getLocalisationMessage("address_uz")}
                fullWidth={true}
              />
              <FormTextField
                name="addressRu"
                label={getLocalisationMessage("address_ru")}
                fullWidth={true}
              />
              <FormTextField
                name="addressEn"
                label={getLocalisationMessage("address_en")}
                fullWidth={true}
              />

              <FormTextField
                name="lat"
                margin="normal"
                label={getLocalisationMessage("lat")}
                fullWidth={true}
              />
              <FormTextField
                name="lon"
                margin="normal"
                label={getLocalisationMessage("lon")}
                fullWidth={true}
              />

              <div>
                <FormSelectField
                  name="type"
                  autoWidth={true}
                  fullWidth={true}
                  label={`${getLocalisationMessage("type", "Type")} *`}
                  options={WarehouseTypes}
                  formatOption={option =>
                    getLocalisationMessage(
                      `warehouse_${option.toLowerCase()}`,
                      option,
                    )
                  }
                  style={{ marginTop: 10 }}
                  variant="outlined"
                />
              </div>
              <FormWarehouseAutoComplete
                hintText={getLocalisationMessage("warehouse", "Warehouse")}
                label={getLocalisationMessage("parent_department", "Warehouse")}
                name="parent"
                fullWidth={true}
                disabled={true}
                margin="normal"
              />
              <FormWarehouseChips
                name="children"
                fullWidth={true}
                label="Дочерние отделения"
                margin="normal"
              />
              <FormSelectField
                name="status"
                autoWidth={true}
                fullWidth={true}
                style={{ marginBottom: 10 }}
                label={getLocalisationMessage("status", "Status")}
                options={OverallStatus}
                formatOption={option =>
                  formatText(getLocalisationMessage(option, option))
                }
                variant="outlined"
              />
              <MuiPhoneNumber
                disableAreaCodes={true}
                margin="normal"
                defaultCountry={countryCode ? countryCode.toLowerCase() : "uz"}
                name="phone"
                value={values.phone || ""}
                onChange={v => {
                  change("phone", v);
                }}
                variant={OUTLINED}
                size="small"
                fullWidth={true}
                label={getLocalisationMessage("phone_number", "Phone Number")}
              />

              <FormSelectField
                name="locker"
                fullWidth={true}
                options={serviceTypes}
                formatOption={x => _.get(x, "name")}
                label={getLocalisationMessage("locker_post")}
                loading={serviceTypesIsLoading}
                disabled={!serviceTypes.length}
                compareOptions={isEqualDataIn("id")}
              />
              <FlexBox flex={true}>
                <FieldArray
                  name="workingDays"
                  props={{
                    getLocalisationMessage,
                    classes,
                  }}
                  component={WorkingDays}
                />
              </FlexBox>
              <FormCheckbox
                name="outboundOfficeOfExchange"
                label={getLocalisationMessage("is_outbound_office_of_exchange")}
              />
              <FormCheckbox
                name="inboundOfficeOfExchange"
                label={getLocalisationMessage("is_inbound_office_of_exchange")}
              />

              {warehouseId > 0 && (
                <div className="warehouseQRCode" style={{ marginTop: 10 }}>
                  <QRCode value={warehouseId.toString()} />
                </div>
              )}

              <FlexBox flex={true}>
                {warehouseId > 0 && (
                  <CustomButton
                    variant="text"
                    color={SECONDARY}
                    onClick={() => {
                      const canvas: any = document.querySelector(
                        ".warehouseQRCode > canvas",
                      );
                      canvas.toBlob(blob => {
                        saveAs(blob, "warehouse-qr-code.png");
                      });
                    }}
                  >
                    {getLocalisationMessage(
                      "download_qrcode",
                      "Download QRCode",
                    )}
                  </CustomButton>
                )}
                <FlexBox justify="flex-end" flex={true}>
                  <CustomButton
                    variant={CONTAINED}
                    color={SECONDARY}
                    startIcon={<Save />}
                    style={{
                      marginRight: 20,
                    }}
                    type="submit"
                  >
                    {getLocalisationMessage("save", "Save")}
                  </CustomButton>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true} direction="column" className={classes.map}>
              <LeafletMapWrapper
                onClick={x => {
                  change("lat", x.latlng.lat);
                  change("lon", x.latlng.lng);
                }}
              >
                <CustomButton
                  variant={CONTAINED}
                  color={SECONDARY}
                  style={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                    zIndex: 450,
                  }}
                >
                  Загрузить KML файл
                </CustomButton>

                {isValidCoordinates({
                  lat: values.lat,
                  lng: values.lon,
                }) && (
                  <div>
                    <LeafletPanTo
                      latLng={{
                        lat: Number(values.lat),
                        lng: Number(values.lon),
                      }}
                    />
                    <LeafletBrandMarker
                      position={{
                        lat: Number(values.lat),
                        lng: Number(values.lon),
                      }}
                    />
                    <FitBounds
                      latLngBounds={[
                        {
                          lat: Number(values.lat),
                          lng: Number(values.lon),
                        },
                      ]}
                    />
                  </div>
                )}
                {polygon.length > 0 && (
                  <LeafletDataPolygon
                    fillColor="red"
                    strokeColor="red"
                    geometry={polygon}
                  />
                )}
              </LeafletMapWrapper>

              <div className={classes.point}>
                <Geolocation
                  onSuccess={({ coords: { latitude, longitude } }) => {
                    change("lat", latitude);
                    change("lon", longitude);
                  }}
                  render={({ getCurrentPosition }) => (
                    <div>
                      <Fab
                        data-cy="geo_find_me"
                        size="small"
                        onClick={getCurrentPosition}
                        style={{ backgroundColor: "#fff" }}
                      >
                        <MapsMyLocation style={{ fill: primary1 }} />
                      </Fab>
                    </div>
                  )}
                />
              </div>
            </FlexBox>
          </FlexBox>
        </CardContent>
      </Card>
    </form>
  );
}

export default enhancer(AdminWarehouseForm);
