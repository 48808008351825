import React from "react";
import { connect } from "react-redux";
import AdminSignIn from "./AdminSignIn";
import { fetchUser } from "../../actions/ProfileActions";
import DashboardRoot from "../../components/app-core/DashboardRoot";
import FlexBox from "../../components/ui-core/FlexBox";

function AdminRoot(props) {
  return (
    <FlexBox direction="column" style={{ height: "100%" }}>
      <DashboardRoot {...props} SignIn={AdminSignIn} />
    </FlexBox>
  );
}

export default connect(null, { fetchUser })(AdminRoot);
