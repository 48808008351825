import { Observable } from "rxjs";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { FirebaseSDK } from "./FirebaseSDK";
import { formatDateToUrl, formatDateTimeToUrl } from "../helpers/FormatUtils";
import { getInitialState } from "../helpers/InitialState";
import { getTokenUserId } from "../../shared/reducers/AuthReducer";

const basePath = "order_bin_validation";
const jobPath = `${basePath}/job`;
const logsPath = `${basePath}/logs`;

const uid = getTokenUserId(getInitialState());

const mapResponse = fp.flow(fp.method("val"), fp.toPlainObject, fromJS);
const parseString = fp.flow(fp.trim, fp.split(","), fp.compact);
const validateOrder = x => {
  const value = x.val();

  return Boolean(value && value.number === x.key);
};

export class OrderBinValidationDB {
  constructor(warehouseId = null) {
    this.db = new FirebaseSDK();
    this.warehouseId = warehouseId;
    this.userId = uid;
  }

  getPath(...args) {
    return [jobPath, this.warehouseId, ...args].join("/");
  }

  //
  // History
  //

  trackAction(type, payload = {}) {
    this.db
      .push(
        [
          logsPath,
          formatDateToUrl(new Date()),
          this.warehouseId,
          type,
          uid,
        ].join("/"),
        {
          ...payload,
          time: formatDateTimeToUrl(new Date()),
        },
      )
      .toPromise();
  }

  //
  // Tasks
  //

  getTasksPath(...args) {
    return this.getPath("tasks", uid, ...args);
  }

  getTask(taskId = null) {
    return this.db.get(this.getTasksPath(taskId));
  }

  getTasks() {
    return this.db.get(this.getTasksPath()).map(mapResponse);
  }

  getTaskAddStream() {
    return this.db.onChildAdd(this.getTasksPath());
  }

  addTask(orderNumber, task) {
    return this.db.push(this.getTasksPath(), { task, number: orderNumber });
  }

  updateTask(taskId = null, values = null) {
    return this.db.update(this.getTasksPath(taskId), values);
  }

  removeTask(taskId = null) {
    return this.db.set(this.getTasksPath(taskId), null);
  }

  retryTask(taskId = null) {
    return this.getTask(taskId)
      .take(1)
      .takeWhile(snapshot => snapshot.exists())
      .switchMap(response => {
        const payload = response.val();

        return this.removeTask(taskId).concat(
          this.addTask(payload.number, payload.task),
        );
      });
  }

  checkDuplicated(orderNumber) {
    return this.addTask(orderNumber, { duplicated: true });
  }

  refreshOrder(orderNumber) {
    return this.addTask(orderNumber, { refresh: true });
  }

  checkBatchCompleted(orderNumber, completed) {
    return this.addTask(orderNumber, { completed });
  }

  addBarcodeChildren(barcode) {
    return this.addTask(barcode, { children: true });
  }

  addBarcodeParcelChildren(orderNumber, barcodes) {
    return this.addTask(orderNumber, { barcode_parcel_children: { barcodes } });
  }

  updateShipmentStatus(barcode, params) {
    return this.addTask(barcode, {
      shipment_status_update: params,
    });
  }

  updateBatchStatus(barcode, params, recursive = false) {
    return this.addTask(barcode, {
      batch_status_update: {
        ...params,
        barcode,
        with_recursive: recursive,
      },
    });
  }

  updateRegistryStatus(barcode, params, recursive = false) {
    return this.addTask(barcode, {
      registry_status_update: {
        ...params,
        barcodes: barcode,
        with_recursive: recursive,
      },
    });
  }

  batchUpdateComplete(barcode) {
    return this.addTask(barcode, {
      complete_batch_update: [barcode],
    });
  }

  forceCompleteBatch(barcode, forceComplete) {
    return this.addTask(barcode, { forceComplete });
  }

  //
  // Batch Validation functions
  //

  getBatchPath(...args) {
    return this.getPath("batch", ...args);
  }

  getBatches() {
    return this.db.get(this.getBatchPath()).map(mapResponse);
  }

  getBatchOrdersPath(...args) {
    return this.getBatchPath("batch_orders", ...args);
  }

  getBatchOrders() {
    return this.db
      .get(this.getBatchOrdersPath())
      .map(
        fp.flow(mapResponse, response =>
          response.filter((x, key) => Map.isMap(x) && x.get("number") === key),
        ),
      );
  }

  getBatchOrderScannedChildren(parentId) {
    return this.db
      .get(this.getBatchOrdersPath())
      .map(
        fp.flow(mapResponse, response =>
          response.filter(
            (x, key) =>
              Map.isMap(x) &&
              x.get("number") === key &&
              x.get("parent_id") === parentId &&
              x.get("scanned"),
          ),
        ),
      );
  }

  getBatchScannedOrders() {
    return this.db
      .get(this.getBatchOrdersPath())
      .map(
        fp.flow(mapResponse, response =>
          response.filter(
            (x, key) =>
              Map.isMap(x) &&
              x.get("number") === key &&
              x.get("scanned") &&
              x.get("scanned_time"),
          ),
        ),
      );
  }

  removeBatchOrders() {
    return this.db.update(this.getBatchPath(), {
      tree: null,
      batch_orders: null,
      queue: null,
    });
  }

  removeOrder(orderNumber) {
    return this.batchRemoveOrders([orderNumber]);
  }

  batchRemoveOrders(orderNumbers) {
    const values = orderNumbers.reduce((acc, number) => {
      acc[number] = null;

      return acc;
    }, {});

    return this.updateBatchOrders(values);
  }

  reloadOrder(orderNumber = null) {
    return this.batchReloadOrders([orderNumber]);
  }

  batchReloadOrders(orderNumbers) {
    const values = orderNumbers.reduce((acc, x) => {
      acc[`${x}/hash`] = null;
      acc[`${x}/failed`] = null;

      return acc;
    }, {});

    return this.db.update(this.getBatchOrdersPath(), values);
  }

  getBatchOrder(orderNumber = null) {
    return this.db.get(this.getBatchOrdersPath(orderNumber)).map(mapResponse);
  }

  getBatchOrderAddStream() {
    return this.db
      .onChildAdd(this.getBatchOrdersPath())
      .filter(validateOrder)
      .map(mapResponse);
  }

  updateBatchOrders(orders) {
    return this.db.update(this.getBatchOrdersPath(), orders);
  }

  updateBatchOrderScannedCount(orderNumber, scannedCount = 0) {
    return this.updateBatchOrders(
      [orderNumber].reduce((acc, number) => {
        acc[`${number}/scanned_count`] = scannedCount;

        return acc;
      }, {}),
    );
  }

  //
  // Current Root Order/Batch
  //

  getRootOrderPath(...args) {
    return this.getBatchPath("root_order", ...args);
  }

  getRootOrder() {
    return this.db.get(this.getRootOrderPath());
  }

  updateRootOrder(orderNumber = null) {
    return this.getRootOrder()
      .take(1)
      .switchMap(response => {
        const payload = response.val();

        return fp.isEmpty(payload)
          ? this.db.update(this.getBatchPath(), { root_order: orderNumber })
          : Observable.of(payload);
      });
  }

  //
  // Batch Counter
  //

  // getBatchOrdersCounterPath(...args) {
  //   return this.getPath("batch_counter", ...args);
  // }
  //
  // updateBatchCounter(orderNumber) {
  //   return this.db.get(this.getBatchOrdersCounterPath()).map(mapResponse);
  // }

  getBatchTreePath(...args) {
    return this.getBatchPath("tree", ...args);
  }

  getBatchTree() {
    return this.db.get(this.getBatchTreePath()).map(mapResponse);
  }

  getBarcodeFromTree(barcode) {
    return this.db.get(this.getBatchTreePath(barcode));
  }

  updateBarcodeTree(orders) {
    return this.db.update(this.getBatchTreePath(), orders);
  }

  addBarcodeToTree(scannedBarcode, parentBarcode) {
    return this.getBarcodeFromTree(parentBarcode)
      .take(1)
      .switchMap(response => {
        const payload = response.val();

        const newPayload = {};
        if (payload) {
          const payloadArray = parseString(payload);
          payloadArray.push(scannedBarcode);
          newPayload[`${parentBarcode}`] = payloadArray.join(",");
        } else if (parentBarcode)
          newPayload[`${parentBarcode}`] = scannedBarcode;

        return this.updateBarcodeTree(newPayload);
      });
  }

  getBatchQueuePath(...args) {
    return this.getBatchPath("queue", ...args);
  }

  getBatchQueue() {
    return this.db
      .get(this.getBatchQueuePath())
      .map(fp.flow(mapResponse, response => response.sort()));
  }

  addBatchQueue(orderNumber) {
    const values = {};
    values[`${orderNumber}`] = Date.now();
    return this.db.update(this.getBatchQueuePath(), values);
  }

  removeBatchQueue(orderNumber) {
    const values = {};
    values[`${orderNumber}`] = null;
    return this.db.update(this.getBatchQueuePath(), values);
  }
}
